import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 15px;
  max-width: 1140px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 115px;
    padding-bottom: 115px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  
  @media (min-width: 768px) {
    padding: 0 25px;
  }
  
  @media (min-width: 1024px) {
    padding: 0;
  }
  
  img {
    max-height: 60px;
  }
`;

const LogoLink = styled(Link)`
  transition: all .4s cubic-bezier(.02,.01,.47,1);
  
  &:hover {
    transform: scale(1.1);
  }
`;

const Dummy = styled.div``;

const ClientsList = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {regex: "//clients//"}
            frontmatter: {
              visibility: {eq: "yes"}
            }
          },
          sort: {fields: [frontmatter___order], order: ASC}
        ) {
          edges {
            node {
              id
              frontmatter {
                logo {
                  publicURL
                  childImageSharp {
                    fluid {
                      originalImg
                    }
                  }
                }
                link
                alt
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <List data-target="clients">
        {
          data.allMarkdownRemark.edges.map(({ node }) => {
            const { logo, alt, link } = node.frontmatter;
            const Outer = link ? LogoLink : Dummy;

            return (
              <Logo key={node.id}>
                <Outer to={link}>
                  <img src={logo && logo.publicURL} alt={alt} />
                </Outer>
              </Logo>
            );
          })
        }
      </List>
    )}
  />
);

export default ClientsList;
